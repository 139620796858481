<template>
<Spinner  ref="spinner" />

<div class="col-12">

<div v-for=" d in doms" v-bind:key="d" class="metric" >

<div class='col-12'>
<div class='col-12'>
<span v-on:click=" d.showMenu=!d.showMenu " style='display: inline-block; float: right; width: 32px; z-index:1; cursor: pointer; ' class='card'>︙</span>
</div>
<div class='card' v-show="d.showMenu" style='padding: 1%; z-index:1;  margin-top:32px;display: inline-block; float: right;  position: absolute; right: 0; background: white;' >
<span  style='cursor:pointer; font-size:18; ' v-on:click="downloadCsv(d.data)" >CSVダウンロード</span>
</div>
</div>

<div  v-html="d.html" ></div>
</div>

</div>
</template>

<script>

import Spinner from "../Spinner.vue";
const Compression = require('../../modules/app/compression'); 
import moment from "moment";


export default {
  name: 'Metric',
    props:[ "path", "Sql"]  ,
    components: {
    Spinner
  },
  data (){
      return {
          showMenu : false , 
          doms : []
      };
  },
  async created (){
   
  },
  async mounted(  ) {
     
        //   await this.fetchData();
    
     

      await this.showData(await window.parent.getSql() ,this.path);   
     
    },
    methods:{
      downloadCsv (data){
          
            var csv = data.columns.join(',') ;  
            
            for (  const i in data.values  ) { 
                
                 csv =csv +  data.values[i].join(',')+'\n';  
            }
           
           console.log(csv);
            var extention = "csv"
           var exportedFilenmae =  'export.csv';
          var blob = new Blob([csv], { type: 'text/csv;charset=shift_jis;' });

           if ( window.navigator.msSaveBlob) { // for IE 10+
                  
            window.navigator.msSaveBlob(blob, exportedFilenmae);
           } else {
        //anchorを生成してclickイベントを呼び出す。
   
        var link = window.document.createElement("a");
        if (link.download !== undefined) {
           // const b = window.parent.confirm('確認ダイアログに表示する文言');
           
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
        
            window.document.body.appendChild(link);
            link.click();
            window.document.body.removeChild(link);
    
            }
           
           }

      },
      
    async showData ( Sql,path){
       try{
          if(!path){
              path =this.path;
          }
        
        if  (path.includes("https://")){

            const paths = path.split("?");
            paths.shift();
            path ="?" +paths.join("?");
        }
     
     const pa = path.split("?");
     if( 2 <= pa.length ){ 
        const p =  Compression.Compression.parseQueryString(pa[1]);
      if (p.get("c")){
     
        path = await  Compression.Compression.decompressString(p.get("c")) ;
      }
     }

      try{
        path =  decodeURIComponent( path );
      }catch (e){console.log(e);}
     
        var q= path.split('q=')[1].split('&')[0]; 
        const param = new URLSearchParams(  path  ); 
     
       //var  q = param.get('q') ;
      
        const particleSize  = param.get('particle_size')  ;
        const  aggregate  = param.get('agg');
        const startDate   = param.get('start')  ;
        const  endDate  = param.get('end');
        
        q = q.replaceAll("{START_DATE}", startDate );
        q = q.replaceAll("{END_DATE}",endDate);   
        q = q.replaceAll("{PARTICLE_SIZE}", particleSize);
        q = q.replaceAll("{TEMPORAL_GRANULARITY}", particleSize );
        q = q.replaceAll("{AGGREGATE}",  aggregate );  
        q = q.replace("{TODAY}",  moment().format('YYYY-MM-DD') );   
        q = q.replace("{TODAY+1D}", moment().add('d',1).format('YYYY-MM-DD') );   
   
        //var resu ;
        while( this.doms.pop() );
        
        Sql.executeAsyncQueue( q , (resu)=>{ 
      
             this.$refs.spinner.show(false);
        
               if(!resu || resu.length===0  ){ return; }
               
               // this.html = "";
           
               for ( var i = 0 ;   i  < resu.length  ;  i ++ ){
            
               var res = resu[i];

                var dom = { showMenu : false };
                dom .data = JSON.parse(JSON.stringify( res )) ;
                
                 dom.html ="<table style='display: inline-block; width: 100%;'><tbody style='display: inline-block; width: 100%;'>";
                   
               const w = 100/ res.columns.length;
                var t = res.columns.shift();
                
              dom.html +="<tr style='display: inline-block; width: 100%;'>";
               for(;t;){
              
                  dom.html+= "<th style='display:inline-block; width:"+w+"%; '>" + t + "</th>";
                    t =  res.columns.shift();
               }
             
               dom.html +="</tr>"
                 
                 console.log(values);
                for ( var values = res.values.shift() ; values ; values = res.values.shift()){ 
               
                
                   dom. html +="<tr style='display: inline-block; width: 100%;'>";
                //    console.log( values);
                    for( var v = values.shift() ;v!==undefined; v = values.shift()){
                        if (v===null){
                            v= "-";
                        }
                        dom.html += "<td style='display:inline-block; width:"+w+"%;'>" + v + "</td>";
                    }
                    dom.html +="</tr>";
               }
                  dom.html +="</tbody></table>";
                   
                     this.doms .push(dom);
                   
              }
        
         //console.log(res);
            });
             this.$refs.spinner.show(true);
          await Sql.executeAsync();
        
       //  console.log(this.doms);
       }catch (e){
           console.log(e);  
        }
    }
    }
};

</script>
<style>
/* 基本のスタイル */
.metric {
  font-size: 24px; /* デフォルトは大きいディスプレイ用 */
}

/* スマホ用のメディアクエリ */
@media screen and (max-width: 767px) {
  .metric {
    font-size: 14px;
  }
}
</style>
