
import { defineComponent } from "vue";

export default defineComponent({
data(){
      return {
         isShow : false 
      }
  },
methods:{
    async show(b:boolean){ 

         this.isShow = b;
    }
  }

});
