<template>

<Metric ref="metric" :path="path" >
</Metric>
<hr>
 <div style="height:200px;">
    
       <MonacoEditor 
        class="editor" 
        ref="editor"
        :options="option"
        v-model="text"
        language="sql" 
        @change="editorDidChange"
       >
       </MonacoEditor>
    
    </div>
<button class="btn btn-dark" v-on:click="updateSql" > SQLを実行 </button>


</template>

<script>

import MonacoEditor from 'monaco-editor-vue3'
import Metric from "../../components/chart/Metric.vue";
const Compression = require('../../modules/app/compression'); 


export default {
  name: 'MetricView',
  components:  { Metric,MonacoEditor } ,
  data(){  return {
        path : "" ,
        text : "" , 
        sq : true , 
        option : {"value":this.text}
        };
    },
  async created( ) {
      
      this.path = window.location.href;   
      const param = new URLSearchParams( encodeURI(window.location.search));
       const q = param.get('q');
       

         try{
         this.sq =  ! ( param.get('sq') == "0") ;
         }catch (e){ console.log(e);}
       if(q){
      
      try{ 
        const dq = window.decodeURIComponent(window.decodeURIComponent(window.decodeURIComponent(q)));
      
        this.text = dq;
        this.option.value = dq;
      
      } catch (e) {
           try{ 
          const dq = window.decodeURIComponent(window.decodeURIComponent(q));
          this.text = dq;
          this.option.value = dq;
          }catch (e){

            const dq = window.decodeURIComponent(q);
            this.text = dq;
            this.option.value = dq;
          
          }
       }
       }
       await this.updateSql ();
               },
    methods: {
        
        editorDidChange(text){     
            this.text  = text; 
            this.option.value = text;
        }, 
       async updateSql (){ 
   
   if (!this.$refs.metric) { return ;}
        // const params = new URLSearchParams( window.location.search) // クエリパラメータを操作するためのオブジェクト
        
        const params = Compression.Compression.parseQueryString( window.location.search );
        //params.set('q',  encodeURIComponent ( this.option.value) ) // クエリパラメータに存在しない場合は追加、存在しているときは更新を行う
       // window.location.search = params.toString() ;
        // const q=encodeURIComponent ( this.option.value);
        
        let queryString="";
         params.forEach((value, key) => {
        queryString += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        });
        
        
        const ec =  await Compression.Compression.compressString(this.option.value);
        const path =  window.location.href.split('?')[0] + "?c=" +  await Compression.Compression.compressString( "q="+this.option.value+queryString );


        if ( false !== this.sq && window.parent !== window && !window.isCrossOrigin){
     
            const param = new URLSearchParams(window.parent.location.search);
       
            const id = param.get('id');
                             
            if (id){
              
              window.parent.history.pushState( {}, '', new URL( window.parent.location.href).pathname + "?ec="+ ec  + '&id=' + id ) ;  
            } else {
                
              window.parent.history.pushState( {}, '', new URL( window.parent.location.href).pathname + "?ec="+ec ) ;  
            }   
        }


           await this.$refs.metric.showData(window.parent.Sql,  path  );

        }
    }
}

</script>

<style>
main{
width: 100% !important ;
}

.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}</style>